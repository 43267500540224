'use client' // Error components must be Client Components

import {useEffect, useState} from 'react'
import React from 'react'
import Bugsnag from '@bugsnag/js'

function CodeTextBlock(props) {
   return (
       <code className={'text-[10px] leading-none flex flex-col max-w-full px-4 empty:hidden'}>
           <p className={'break-words'}>
               {props.children}
           </p>
       </code>
   )
}

export default function Error({error, reset}) {

    const [metadata, setMetadata] = useState({
        t: Date.now(),
        m: btoa(error?.message ?? ''),
        s: btoa(error?.stack ?? ''),
        d: btoa(error?.digest ?? '')
    })

    useEffect(() => {
        // Log the error to an error reporting service
        Bugsnag.notify(error);
    }, [error])

    console.error(error)

    return (
        <div className={'fixed inset-0 flex flex-col items-center justify-center gap-[15px]'}>
            <h2>Something went wrong!</h2>
            <button
                className={'h-[32px] rounded-md bg-indigo-600 text-white w-full max-w-[200px]'}
                onClick={
                    // Attempt to recover by trying to re-render the segment
                    () => reset()
                }
            >
                Try again
            </button>

            <div className={'flex flex-col gap-2 mt-5 overflow-hidden justify-center ites-center max-w-full'}>
                <CodeTextBlock>
                    Error:
                </CodeTextBlock>

                <CodeTextBlock>
                    {metadata.t}
                </CodeTextBlock>

                <CodeTextBlock>
                    {metadata.d}
                </CodeTextBlock>

                <CodeTextBlock>
                    {metadata.m}
                </CodeTextBlock>

                <CodeTextBlock>
                    {metadata.s}
                </CodeTextBlock>
            </div>

        </div>
    )
}
